<template>
  <v-navigation-drawer
    class="white-nav"
    v-model="drawer"
    app
    dark
    temporary
  >
    <v-list>
      <v-list-item
        v-for="(link, i) in links"
        :key="i"
        :to="link.to"
        :href="link.href"
        @click="onClick($event, link)"
      >
        <v-list-item-title v-text="link.text" />
      </v-list-item>
      <v-list-item
        @click="triggerModal()"
      >
        {{ content.contact }}
      </v-list-item>
      <v-list-item>
        <v-icon class="icon-language ma-2">
          mdi-translate
        </v-icon>
      </v-list-item>
      <v-list-item>
        <a
          class="language-btn"
          @click="setLang('es')"
        >ES</a>
      </v-list-item>
      <v-list-item>
        <a
          class="language-btn"
          @click="setLang('en')"
        >EN</a>
      </v-list-item>
      <v-list-item>
        <a
          class="language-btn"
          @click="setLang('fr')"
        >FR</a>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapGetters,
    mapMutations,
  } from 'vuex'
  import { languageMethods } from '../../shared'

  export default {
    name: 'CoreDrawer',
    mixins: [languageMethods],
    computed: {
      ...mapGetters(['links']),
      content () {
        return this.$store.state.webpagecontent[0][this.$store.state.lang].menuHeader
      },
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
    },

    methods: {
      ...mapMutations(['setDrawer', 'showModal']),
      onClick (e, item) {
        e.stopPropagation()

        if (item.to === '/') {
          this.$vuetify.goTo(0)
          this.setDrawer(false)
          return
        }

        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href)
        this.setDrawer(false)
      },
      triggerModal() {
        this.showModal()
      },
      setLang (lang) {
        this.setCurrentLanguage(lang)
      },
    },
  }
</script>

<style scoped>
  .language-btn-div {
    color: #e2eef1;
  }
  .icon-language {
    color: #e2eef1 !important;
  }
  .language-btn {
    padding: 5px;
    border-radius: 5px;
  }
</style>
